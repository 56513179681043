export default {
  clearTimesheets: (state) => {
    state.timesheets = []
  },
  
  setTimesheets: (state, {timesheets, replace}) => {
    state.timesheets = 
      replace 
        ? timesheets
        : [].concat(state.timesheets).concat(timesheets)
  },

  setTimesheetsMeta: (state, meta) => {
    state.timesheetsMeta = meta;
  },

  setTimesheet: (state, timesheet) => {
    state.timesheet = timesheet;
  },

  setPendingTimesheets: (state, isPending = true) => {
    state.isTimesheetsPending = isPending;
  },

  setTimesheetMessage: (state, msgs) => {
    state.timesheet.messages = msgs;
  },

  toggleData: state => {
    state.hasData = true;
  },

  setFilters: (state, filters) => {
    state.filters = filters;
  },

  setError: (state, error) => {
    state.error = error;
  },

  setExcelUrl: (state, url) => {
    state.excelUrl = url;
  },
};
