export default {
  getTimesheets: state => {
    return state.timesheets || [];
  },

  getTimesheetsMeta: state => {
    return state.timesheetsMeta
  },

  getTimesheet: state => {
    return state.timesheet;
  },

  getFilters: state => {
    return state.filters || [];
  },

  getError: state => {
    return state.error;
  },
  getExcelUrl: state => {
    return state.excelUrl
  },

  isTimesheetsPending: state => {
    return state.isTimesheetsPending
  },
};
