<template>
  <ui-modal
    class="ui-confirm"
    ref="modal"
    role="alertdialog"
    :dismiss-on="dismissOn"
    :dismissible="!loading"
    :title="title"
    :transition="transition"
    :size="size"
    @close="onModalClose"
    @hide="onModalHide"
    @open="onModalOpen"
    @reveal="onModalReveal"
  >
    <div class="ui-confirm__message">
      <slot></slot>
    </div>

    <div class="ui-confirm__footer" slot="footer">
      <UiButtonNew
        ref="confirmButton"
        :icon="confirmIcon"
        :showIcon="(confirmIcon?.length ?? 0) != 0"
        :loading="loading"
        @click.native="confirm"
        >{{ confirmButtonText }}</UiButtonNew>

      <UiButtonNew
        v-if="denyButtonText?.length"
        style="flex-grow: .5"
        class="btn-transparent__border"
        ref="denyButton"
        type="secondary"
        :disabled="loading"
        :icon="denyIcon"
        :showIcon="(denyIcon?.length ?? 0) != 0"
        @click.native="deny"
        >
        {{ denyButtonText }}
    </UiButtonNew>

  </div>
  </ui-modal>
</template>

<script>
import UiButton from './UiButton.vue';
import UiModal from './UiModal.vue';

import classlist from './helpers/classlist';

export default {
  name: 'ui-confirm',

  props: {
    title: {
      type: String,
      default: 'UiConfirm',
    },
    type: {
      type: String,
      default: 'primary', // any of the color prop values of UiButton
    },
    size: String,
    confirmButtonText: {
      type: String,
      default: 'OK',
    },
    confirmButtonIcon: String,
    denyButtonText: {
      type: String,
      default: 'Cancel',
    },
    denyButtonIcon: String,
    autofocus: {
      type: String,
      default: 'deny-button', // 'confirm-button', 'deny-button' or 'none'
    },
    closeOnConfirm: {
      type: Boolean,
      default: true,
    },
    dismissOn: String,
    transition: String,
    loading: {
      type: Boolean,
      default: false,
    },
    confirmIcon: {
      type: String,
      default: 'tick'
    },
    denyIcon: {
      type: String,
      default: 'x'
    }
  },

  computed: {
    confirmButtonColor() {
      const typeToColor = {
        default: 'default',
        primary: 'primary',
        accent: 'accent',
        success: 'green',
        warning: 'orange',
        danger: 'red',
      };

      return typeToColor[this.type];
    },
  },

  methods: {
    open() {
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    },

    confirm() {
      this.$emit('confirm');

      if (this.closeOnConfirm) {
        this.$refs.modal.close();
      }
    },

    deny() {
      this.$refs.modal.close();
      this.$emit('deny');
    },

    onModalOpen() {
      let button;

      if (this.autofocus === 'confirm-button') {
        button = this.$refs.confirmButton.$el;
      } else if (this.autofocus === 'deny-button') {
        button = this.$refs.denyButton.$el;
      }

      if (button) {
        classlist.add(button, 'has-focus-ring');
        button.addEventListener('blur', this.removeAutoFocus);
        button.focus();
      }

      this.$emit('open');
    },

    onModalReveal() {
      this.$emit('reveal');
    },

    onModalClose() {
      this.$emit('close');
    },

    onModalHide() {
      this.$emit('hide');
    },

    removeAutoFocus() {
      let button;

      if (this.autofocus === 'confirm-button') {
        button = this.$refs.confirmButton.$el;
      } else if (this.autofocus === 'deny-button') {
        button = this.$refs.denyButton.$el;
      }

      if (button) {
        classlist.remove(button, 'has-focus-ring');

        // This listener should run only once
        button.removeEventListener('blur', this.removeAutoFocus);
      }
    },
  },

  components: {
    UiButton,
    UiModal,
  },
};
</script>

<style lang="scss">
@import './styles/imports';

.ui-confirm {
  &__message {
    font-size: rem(15px);
    white-space: normal;
  }
  .ui-modal__header {
    padding-top: 1rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    padding-left: 60px;
    padding-right: 60px;

  }

  .ui-modal__footer {
    padding: 25px 0 60px;
    height: auto;
  }
}
</style>
