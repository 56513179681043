export default {
  setManagers: (state, managers) => {
    state.managers = managers;
  },
  setManagerSubscribes: (state, payload) => {
    state.manager.sites.find( site => site.id === payload.site_id).access.subscribed = payload.subscribed
  },
  setManager: (state, manager) => {
    state.manager = manager;
  },
  toggleData: state => {
    state.hasData = true;
  },
  setError: (state, error) => {
    state.error = error;
  },
};
