
export default {
  async fetch({ commit }) {
    try {
      const notifications = await this.$axios.get('/api/me/notifications');
      const { data } = notifications.data;
      commit('setNotifications', data);
    } catch (err) {
      commit('setError', err);
    }
  },

  add({ commit, rootGetters, dispatch }, notification) {
    
    if (notification.type === 'worker.onsite.change') {
      dispatch('workers/onsiteStatusChange', notification, {root: true})
    } else {

      commit('addNotification', notification)      

      //TODO: Convert to timesheet speicifc notification
      //If we receive any notification, update the timesheets pending flag
      const isTimesheetsPending = rootGetters['timesheets/isTimesheetsPending']
      if (!isTimesheetsPending) {
        dispatch('timesheets/fetchTimesheetsPending', null, {root: true})
      }
    }



  },

  async markRead({ commit, dispatch }, id) {
    try {
      const markRead = await this.$axios.put(
        `/api/me/notifications/mark-read${id ? '/' + id : ''}`
      );
      dispatch('fetch');
    } catch (err) {
      commit('setError', err);
    }
  },
};
