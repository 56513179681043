export default () => ({
  timesheets: [],
  timesheetsMeta: null,
  timesheet: null,
  excelUrl:null,
  filters: null,
  hasData: false,
  error: '',
  isTimesheetsPending: false,
});
