import { EventBus } from '~/plugins/event-bus';
import moment from 'moment';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, commit, state }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit }, payload) {
    try {
      const response = await this.$axios.get('/api/me/contracts', {
        params: payload.query,
      });
      const { data, links, meta } = response.data;
      if (payload.reset) {
        commit('setContracts', data);
      } else {
        commit('addContracts', data);
      }
      commit('setLinks', links);
      commit('setMeta', meta);
      commit('toggleData');
    } catch (err) {
      commit('setMeta', err);
    }
  },

  async fetchWorkerContracts({ commit }, payload) {
    const { query, slug } = payload;
    try {
      commit('setContracts', [])
      const response = await this.$axios.get('/api/me/contracts/' + slug, {
        params: query,
      });
      const { data, meta } = response.data;
      commit('setContracts', data)
      commit('setMeta', meta)
      commit('toggleData')
    } catch (err) {
      commit('setMeta', err)
    }
  },

  async newWorkerContract({ commit }, slug) {
    try {
      const response = await this.$axios.post('/api/me/contracts/' + slug);
      const { data, meta } = response.data;
      commit('setContracts', data);
      commit('setMeta', meta);
      commit('toggleData');
    } catch (err) {
      commit('setMeta', err);
    }
  },

  async fetchDetails({ commit }, data) {
    commit('setContract', null);
    const { id, slug } = data;
    try {
      const contract = await this.$axios.get(`/api/me/contracts/${slug}/${id}`);
      commit('setContract', contract.data.data);
    } catch (err) {}
  },

  async store({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      await this.$axios.post('/api/me/contracts', payload);
      EventBus.$emit('createSnackbar', {
        message: `Contract added successfully`,
      });
      location.reload();
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async extend({ commit, dispatch }, payload) {
    enableLoader()
    const { id, end_date } = payload;
    commit('setError', '');
    try {
      let extended = await this.$axios.put('/api/me/contracts/' + id, {
        end_at: end_date,
      });

      EventBus.$emit('createSnackbar', {
        message: `Contract extended till ${moment(
          extended.data.data.end_at
        ).format('DD.MM.YYYY')}`,
      });
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async edit({ commit, dispatch }, payload) {
    enableLoader()
    const { id, hourly_rate } = payload;
    commit('setError', '');
    try {
      let edited = await this.$axios.put('/api/me/contracts/' + id, {
        hourly_rate: hourly_rate,
      });

      EventBus.$emit('createSnackbar', {
        message: `Contract hourly rate changed to ${edited.data.data.hourly_rate}`,
      });

      const newId = edited.data.data.id;
      if (newId !== id) {
        return newId;
      }
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async terminate({ commit }, id) {
    enableLoader()
    commit('setError', '');
    try {
      let cancel = await this.$axios.delete('/api/me/contracts/' + id);
      EventBus.$emit('createSnackbar', {
        message: `Contract terminated successfully`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Contract could not be terminated`,
      });
    }
    disableLoader()
  },
};
