export default {
  setDict: (state, data) => {
    state.data = data
  },
  setLocations: (state, data) => {
    state.locations = data
  },
  setHasData: state => {
    state.hasData = true
  },
  toggleInitialised: (state, flag) => {
    state.isInitialised = flag
  },
};
