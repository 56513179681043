import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f70c12d8 = () => interopDefault(import('../pages/assignments/index.vue' /* webpackChunkName: "pages/assignments/index" */))
const _65d1a5d8 = () => interopDefault(import('../pages/card/index.vue' /* webpackChunkName: "pages/card/index" */))
const _55c130c9 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _3c68f592 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _756e81a4 = () => interopDefault(import('../pages/managers/index.vue' /* webpackChunkName: "pages/managers/index" */))
const _21f61ad8 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _4eead846 = () => interopDefault(import('../pages/more/index.vue' /* webpackChunkName: "pages/more/index" */))
const _67d57cdf = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _69c44cab = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _2ffc5c42 = () => interopDefault(import('../pages/site-control/index.vue' /* webpackChunkName: "pages/site-control/index" */))
const _1c1e1fcc = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _fc82606e = () => interopDefault(import('../pages/timesheets/index.vue' /* webpackChunkName: "pages/timesheets/index" */))
const _5f625a7a = () => interopDefault(import('../pages/workers/index.vue' /* webpackChunkName: "pages/workers/index" */))
const _a437e0ee = () => interopDefault(import('../pages/invitation/accept.vue' /* webpackChunkName: "pages/invitation/accept" */))
const _47e31ccd = () => interopDefault(import('../pages/invitation/deny.vue' /* webpackChunkName: "pages/invitation/deny" */))
const _520951b5 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _52c87e0f = () => interopDefault(import('../pages/register/owner/index.vue' /* webpackChunkName: "pages/register/owner/index" */))
const _75cb4d38 = () => interopDefault(import('../pages/register/worker/index.vue' /* webpackChunkName: "pages/register/worker/index" */))
const _7e78b649 = () => interopDefault(import('../pages/settings/account/index.vue' /* webpackChunkName: "pages/settings/account/index" */))
const _442c75d2 = () => interopDefault(import('../pages/settings/billing/index.vue' /* webpackChunkName: "pages/settings/billing/index" */))
const _5d4dc499 = () => interopDefault(import('../pages/settings/company/index.vue' /* webpackChunkName: "pages/settings/company/index" */))
const _d620da58 = () => interopDefault(import('../pages/settings/helpdesk/index.vue' /* webpackChunkName: "pages/settings/helpdesk/index" */))
const _2df3f430 = () => interopDefault(import('../pages/sites/new.vue' /* webpackChunkName: "pages/sites/new" */))
const _5fe73619 = () => interopDefault(import('../pages/workers/link.vue' /* webpackChunkName: "pages/workers/link" */))
const _9fe2dad0 = () => interopDefault(import('../pages/workers/link-spreadsheet.vue' /* webpackChunkName: "pages/workers/link-spreadsheet" */))
const _2a534abc = () => interopDefault(import('../pages/login/password/forgot.vue' /* webpackChunkName: "pages/login/password/forgot" */))
const _c8e78474 = () => interopDefault(import('../pages/login/password/reset.vue' /* webpackChunkName: "pages/login/password/reset" */))
const _55b17803 = () => interopDefault(import('../pages/register/owner/payment.vue' /* webpackChunkName: "pages/register/owner/payment" */))
const _f7a60d9a = () => interopDefault(import('../pages/register/owner/subscriptions.vue' /* webpackChunkName: "pages/register/owner/subscriptions" */))
const _2e9e2079 = () => interopDefault(import('../pages/register/owner/thank-you.vue' /* webpackChunkName: "pages/register/owner/thank-you" */))
const _a57df5da = () => interopDefault(import('../pages/register/old-worker/step/2.vue' /* webpackChunkName: "pages/register/old-worker/step/2" */))
const _a561c6d8 = () => interopDefault(import('../pages/register/old-worker/step/3.vue' /* webpackChunkName: "pages/register/old-worker/step/3" */))
const _3459729d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _060e8e40 = () => interopDefault(import('../pages/site-control/people/_id/index.vue' /* webpackChunkName: "pages/site-control/people/_id/index" */))
const _4b76c93f = () => interopDefault(import('../pages/visitors/invite/_hash/index.vue' /* webpackChunkName: "pages/visitors/invite/_hash/index" */))
const _20ca5224 = () => interopDefault(import('../pages/visitors/qr/_id/index.vue' /* webpackChunkName: "pages/visitors/qr/_id/index" */))
const _5af688e2 = () => interopDefault(import('../pages/site-control/people/_id/check-in/index.vue' /* webpackChunkName: "pages/site-control/people/_id/check-in/index" */))
const _8bf84418 = () => interopDefault(import('../pages/workers/profile/_slug/edit.vue' /* webpackChunkName: "pages/workers/profile/_slug/edit" */))
const _ace4b102 = () => interopDefault(import('../pages/assignments/_id/index.vue' /* webpackChunkName: "pages/assignments/_id/index" */))
const _1215df13 = () => interopDefault(import('../pages/card/_slug/index.vue' /* webpackChunkName: "pages/card/_slug/index" */))
const _71779f22 = () => interopDefault(import('../pages/invite/_hash/index.vue' /* webpackChunkName: "pages/invite/_hash/index" */))
const _78dbc434 = () => interopDefault(import('../pages/managers/_slug.vue' /* webpackChunkName: "pages/managers/_slug" */))
const _2f21fd6f = () => interopDefault(import('../pages/profile/_slug.vue' /* webpackChunkName: "pages/profile/_slug" */))
const _28406e05 = () => interopDefault(import('../pages/sites/_id/index.vue' /* webpackChunkName: "pages/sites/_id/index" */))
const _0ef1adc4 = () => interopDefault(import('../pages/timesheets/_timesheet_id.vue' /* webpackChunkName: "pages/timesheets/_timesheet_id" */))
const _22d0b778 = () => interopDefault(import('../pages/verify-email/_id.vue' /* webpackChunkName: "pages/verify-email/_id" */))
const _13353438 = () => interopDefault(import('../pages/visitors/_id/index.vue' /* webpackChunkName: "pages/visitors/_id/index" */))
const _0c5a6fbe = () => interopDefault(import('../pages/workers/_slug/index.vue' /* webpackChunkName: "pages/workers/_slug/index" */))
const _7cb4549f = () => interopDefault(import('../pages/invite/_hash/details.vue' /* webpackChunkName: "pages/invite/_hash/details" */))
const _61ad4f96 = () => interopDefault(import('../pages/invite/_hash/register.vue' /* webpackChunkName: "pages/invite/_hash/register" */))
const _0854ad35 = () => interopDefault(import('../pages/invite/_hash/registered.vue' /* webpackChunkName: "pages/invite/_hash/registered" */))
const _3a67666c = () => interopDefault(import('../pages/invite/_hash/request.vue' /* webpackChunkName: "pages/invite/_hash/request" */))
const _5170d8ea = () => interopDefault(import('../pages/invite/_hash/sent.vue' /* webpackChunkName: "pages/invite/_hash/sent" */))
const _54393eb2 = () => interopDefault(import('../pages/sites/_id/edit.vue' /* webpackChunkName: "pages/sites/_id/edit" */))
const _72e3287a = () => interopDefault(import('../pages/assignments/_id/documents/_mediaId.vue' /* webpackChunkName: "pages/assignments/_id/documents/_mediaId" */))
const _147b8534 = () => interopDefault(import('../pages/sites/_id/subcompany/_subcompany_id/index.vue' /* webpackChunkName: "pages/sites/_id/subcompany/_subcompany_id/index" */))
const _60d4b5fb = () => interopDefault(import('../pages/workers/_slug/assignments/_assignment_id.vue' /* webpackChunkName: "pages/workers/_slug/assignments/_assignment_id" */))
const _0472e390 = () => interopDefault(import('../pages/workers/_slug/contracts/_id.vue' /* webpackChunkName: "pages/workers/_slug/contracts/_id" */))
const _2580a346 = () => interopDefault(import('../pages/workers/_slug/timesheets/_timesheet_id.vue' /* webpackChunkName: "pages/workers/_slug/timesheets/_timesheet_id" */))
const _9cb2eb8a = () => interopDefault(import('../pages/card/_slug/_id.vue' /* webpackChunkName: "pages/card/_slug/_id" */))
const _1671dd00 = () => interopDefault(import('../pages/sites/_id/_slug/index.vue' /* webpackChunkName: "pages/sites/_id/_slug/index" */))
const _1e8ce1cc = () => interopDefault(import('../pages/sites/_id/_slug/edit.vue' /* webpackChunkName: "pages/sites/_id/_slug/edit" */))
const _3f9e2179 = () => interopDefault(import('../pages/sites/_id/_slug/assignments/_assignment_id.vue' /* webpackChunkName: "pages/sites/_id/_slug/assignments/_assignment_id" */))
const _746c3d4a = () => interopDefault(import('../pages/sites/_id/_slug/timesheets/_timesheet_id.vue' /* webpackChunkName: "pages/sites/_id/_slug/timesheets/_timesheet_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/assignments",
    component: _f70c12d8,
    name: "assignments"
  }, {
    path: "/card",
    component: _65d1a5d8,
    name: "card"
  }, {
    path: "/help",
    component: _55c130c9,
    name: "help"
  }, {
    path: "/login",
    component: _3c68f592,
    name: "login"
  }, {
    path: "/managers",
    component: _756e81a4,
    name: "managers"
  }, {
    path: "/messages",
    component: _21f61ad8,
    name: "messages"
  }, {
    path: "/more",
    component: _4eead846,
    name: "more"
  }, {
    path: "/privacy-policy",
    component: _67d57cdf,
    name: "privacy-policy"
  }, {
    path: "/settings",
    component: _69c44cab,
    name: "settings"
  }, {
    path: "/site-control",
    component: _2ffc5c42,
    name: "site-control"
  }, {
    path: "/sites",
    component: _1c1e1fcc,
    name: "sites"
  }, {
    path: "/timesheets",
    component: _fc82606e,
    name: "timesheets"
  }, {
    path: "/workers",
    component: _5f625a7a,
    name: "workers"
  }, {
    path: "/invitation/accept",
    component: _a437e0ee,
    name: "invitation-accept"
  }, {
    path: "/invitation/deny",
    component: _47e31ccd,
    name: "invitation-deny"
  }, {
    path: "/profile/edit",
    component: _520951b5,
    name: "profile-edit"
  }, {
    path: "/register/owner",
    component: _52c87e0f,
    name: "register-owner"
  }, {
    path: "/register/worker",
    component: _75cb4d38,
    name: "register-worker"
  }, {
    path: "/settings/account",
    component: _7e78b649,
    name: "settings-account"
  }, {
    path: "/settings/billing",
    component: _442c75d2,
    name: "settings-billing"
  }, {
    path: "/settings/company",
    component: _5d4dc499,
    name: "settings-company"
  }, {
    path: "/settings/helpdesk",
    component: _d620da58,
    name: "settings-helpdesk"
  }, {
    path: "/sites/new",
    component: _2df3f430,
    name: "sites-new"
  }, {
    path: "/workers/link",
    component: _5fe73619,
    name: "workers-link"
  }, {
    path: "/workers/link-spreadsheet",
    component: _9fe2dad0,
    name: "workers-link-spreadsheet"
  }, {
    path: "/login/password/forgot",
    component: _2a534abc,
    name: "login-password-forgot"
  }, {
    path: "/login/password/reset",
    component: _c8e78474,
    name: "login-password-reset"
  }, {
    path: "/register/owner/payment",
    component: _55b17803,
    name: "register-owner-payment"
  }, {
    path: "/register/owner/subscriptions",
    component: _f7a60d9a,
    name: "register-owner-subscriptions"
  }, {
    path: "/register/owner/thank-you",
    component: _2e9e2079,
    name: "register-owner-thank-you"
  }, {
    path: "/register/old-worker/step/2",
    component: _a57df5da,
    name: "register-old-worker-step-2"
  }, {
    path: "/register/old-worker/step/3",
    component: _a561c6d8,
    name: "register-old-worker-step-3"
  }, {
    path: "/",
    component: _3459729d,
    name: "index"
  }, {
    path: "/site-control/people/:id",
    component: _060e8e40,
    name: "site-control-people-id"
  }, {
    path: "/visitors/invite/:hash",
    component: _4b76c93f,
    name: "visitors-invite-hash"
  }, {
    path: "/visitors/qr/:id",
    component: _20ca5224,
    name: "visitors-qr-id"
  }, {
    path: "/site-control/people/:id/check-in",
    component: _5af688e2,
    name: "site-control-people-id-check-in"
  }, {
    path: "/workers/profile/:slug?/edit",
    component: _8bf84418,
    name: "workers-profile-slug-edit"
  }, {
    path: "/assignments/:id",
    component: _ace4b102,
    name: "assignments-id"
  }, {
    path: "/card/:slug",
    component: _1215df13,
    name: "card-slug"
  }, {
    path: "/invite/:hash",
    component: _71779f22,
    name: "invite-hash"
  }, {
    path: "/managers/:slug",
    component: _78dbc434,
    name: "managers-slug"
  }, {
    path: "/profile/:slug?",
    component: _2f21fd6f,
    name: "profile-slug"
  }, {
    path: "/sites/:id",
    component: _28406e05,
    name: "sites-id"
  }, {
    path: "/timesheets/:timesheet_id",
    component: _0ef1adc4,
    name: "timesheets-timesheet_id"
  }, {
    path: "/verify-email/:id?",
    component: _22d0b778,
    name: "verify-email-id"
  }, {
    path: "/visitors/:id",
    component: _13353438,
    name: "visitors-id"
  }, {
    path: "/workers/:slug",
    component: _0c5a6fbe,
    name: "workers-slug"
  }, {
    path: "/invite/:hash?/details",
    component: _7cb4549f,
    name: "invite-hash-details"
  }, {
    path: "/invite/:hash?/register",
    component: _61ad4f96,
    name: "invite-hash-register"
  }, {
    path: "/invite/:hash?/registered",
    component: _0854ad35,
    name: "invite-hash-registered"
  }, {
    path: "/invite/:hash?/request",
    component: _3a67666c,
    name: "invite-hash-request"
  }, {
    path: "/invite/:hash?/sent",
    component: _5170d8ea,
    name: "invite-hash-sent"
  }, {
    path: "/sites/:id/edit",
    component: _54393eb2,
    name: "sites-id-edit"
  }, {
    path: "/assignments/:id/documents/:mediaId?",
    component: _72e3287a,
    name: "assignments-id-documents-mediaId"
  }, {
    path: "/sites/:id/subcompany/:subcompany_id",
    component: _147b8534,
    name: "sites-id-subcompany-subcompany_id"
  }, {
    path: "/workers/:slug/assignments/:assignment_id?",
    component: _60d4b5fb,
    name: "workers-slug-assignments-assignment_id"
  }, {
    path: "/workers/:slug/contracts/:id?",
    component: _0472e390,
    name: "workers-slug-contracts-id"
  }, {
    path: "/workers/:slug/timesheets/:timesheet_id?",
    component: _2580a346,
    name: "workers-slug-timesheets-timesheet_id"
  }, {
    path: "/card/:slug/:id",
    component: _9cb2eb8a,
    name: "card-slug-id"
  }, {
    path: "/sites/:id/:slug",
    component: _1671dd00,
    name: "sites-id-slug"
  }, {
    path: "/sites/:id/:slug/edit",
    component: _1e8ce1cc,
    name: "sites-id-slug-edit"
  }, {
    path: "/sites/:id/:slug/assignments/:assignment_id?",
    component: _3f9e2179,
    name: "sites-id-slug-assignments-assignment_id"
  }, {
    path: "/sites/:id/:slug/timesheets/:timesheet_id?",
    component: _746c3d4a,
    name: "sites-id-slug-timesheets-timesheet_id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
