import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit, state }, payload) {

    try {

      const response = await this.$axios.get('/api/me/timesheets', {
        params: payload.query,
      })
    
      const { data, meta } = response['data']

      commit('setTimesheets', {
        timesheets: data,
        replace: payload.reset
      })

      commit('setTimesheetsMeta', meta)

      return data

    } catch (err) {
      commit('setTimesheetsMeta', err);
    }

  },

  async fetchTimesheetsPending({ commit, state }) {
    try {
      const pendingStatus = await this.$axios.get('/api/me/timesheets/pending');
      commit('setPendingTimesheets', pendingStatus.data.hasPending);
    } catch (err) {
    }
  },

  async fetchDetails({ commit, state }, id) {
    commit('setTimesheet', null);
    try {
      const timesheet = await this.$axios.get(`/api/me/timesheets/${id}`);
      commit('setTimesheet', timesheet.data.data);
    } catch (err) {}
  },

  async save({ commit, state }, payload) {
    enableLoader()
    const { id, entries, message } = payload;
    try {
      const timesheet = await this.$axios.put(`/api/me/timesheets/${id}`, {
        entries: entries,
        message: {
          type: 'text',
          content: message,
        },
      });

      EventBus.$emit('createSnackbar', {
        message: `Timesheet has been saved successfully.`,
      });

      const responseData = timesheet.data.data;
      if (responseData) {
        commit('setTimesheet', responseData);
      }

      disableLoader()
    } catch (err) {
      disableLoader()
    }
  },

  async markAsPaid({ commit, dispatch}, payload) {
    enableLoader()
    const { id } = payload;
    try {
      const timesheet = await this.$axios.put(`/api/me/timesheets/${id}/paid`);
      EventBus.$emit('createSnackbar', {
        message: `Timesheet has been marked as Paid successfully.`,
      });

      commit('setTimesheet', timesheet.data.data);
      dispatch('fetchTimesheetsPending')
    } catch (err) {}
    disableLoader()
  },

  async timesheetAction({ commit, dispatch }, payload) {
    enableLoader()
    const { id, type } = payload;
    try {
      const timesheet = await this.$axios.put(
        `/api/me/timesheets/${id}/${type}`
      );
      let msg;
      if (type === 'accept') {
        msg = 'Timesheet has been accepted.';
      }
      if (type === 'reject') {
        msg = 'Timesheet has been rejected.';
      }
      if (type === 'submit') {
        msg = 'Timesheet has been submitted for review.';
      }

      EventBus.$emit('createSnackbar', {
        message: msg,
      });

      if (timesheet?.data?.data) {
        commit('setTimesheet', timesheet.data.data);
        dispatch('fetchTimesheetsPending')
      }
    } catch (err) {
      console.log(err);
    }
    disableLoader()
  },

  async sendNote({ commit }, data) {
    const { conversationId, content } = data
    try {
      const response = await this.$axios.post(
        `/api/me/timesheets/${conversationId}/message`,
        {
          message: {
            type: 'text',
            content,
          },
        }
      )
      commit('setTimesheetMessage', response.data)
      EventBus.$emit('createSnackbar', {
        message: 'Note added successfully.',
      })

      return data

    } catch (err) {
      console.error(err);
      return err;
    }
  },
  async fetchFilters({ commit }, workerId = null) {
    try {
      const filters = await this.$axios.get('/api/me/timesheets/filters');
      commit('setFilters', filters.data);
    } catch (err) {
    }
  },

  async checkins({ commit, state }, siteId) {
    try {
      const checkins = await this.$axios.get(
        `/api/me/timesheets/${siteId}/checkins`
      );
      return checkins.data;
    } catch (err) {
      console.log(err);
    }
  },

  async downloadExcelUrl({ commit, state }, id) {
    try {
      const response = await this.$axios.get(`/api/me/timesheets/${id}/excel`);
      commit('setExcelUrl', response.data.data.url);
    } catch (err) {
      commit('setError', err);
    }
  },
};
