import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state }) {
    dispatch('fetch');
  },

  async fetch({ commit }) {
    enableLoader()
    try {
      const response = await this.$axios.get('/api/me/job-posts');
      const { data, links, meta } = response.data;
      commit('setJobs', data);
      commit('setLinks', links);
      commit('setMeta', meta);
      commit('toggleData');
    } catch (err) {
      commit('setError', err);
      commit('setMeta', err);
      EventBus.$emit('createSnackbar', {
        message: `Failed to create Job Post`,
      });
    }
    disableLoader()
  },

  async fetchJob({ commit, state }, jobId) {
    enableLoader()
    commit('setJob', null);
    try {
      const job = await this.$axios.get(`/api/me/job-posts/${jobId}`);
      commit('setJob', job.data.data);
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Failed to create Job Post`,
      });
    }
    disableLoader()
  },

  async new({ commit, state }, data) {
    enableLoader()
    try {
      await this.$axios({
        method: 'post',
        url: `/api/me/job-posts`,
        data: data,
        config: {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      });
      this.$router.push('/jobs');
      EventBus.$emit('createSnackbar', {
        message: `Job Post was added successfully`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', {
        message: `Failed to create Job Post`,
      });
    }
    disableLoader()
  },

  async edit({ commit, state }, payload) {
    enableLoader()
    const { data, id } = payload;
    try {
      const job = await this.$axios({
        method: 'put',
        url: `/api/me/job-posts/${id}`,
        data: data,
        config: {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
      });
      commit('setJob', null);
      this.$router.push('/jobs');
      EventBus.$emit('createSnackbar', {
        message: `Job Post was edited successfully`,
      });
    } catch (err) {
      commit('setError', err);
      EventBus.$emit('createSnackbar', { message: `Failed to edit Job Post` });
    }
    disableLoader()
  },

  async remove({ dispatch, commit, state }, id) {
    enableLoader()
    commit('setJob', null);
    try {
      const job = await this.$axios.delete(`/api/me/job-posts/${id}`);
      dispatch('fetch');

      EventBus.$emit('createSnackbar', {
        message: `Job Post was removed successfully`,
      });
    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: `Failed to remove Job Post`,
      });
    }
    disableLoader()
  },
};
