export default {
    async createForm({commit}, payload) {
        try {
            const response = await this.$axios.post(`api/me/visitors/form/${payload.siteId}`, payload.formData)
            if (response.data.succes) {
                commit('setSucces', true);
                this.$router.go();
            }

        } catch (err) {
            commit('setError', err);
        }
    },

    async fetchFormData({commit}, siteId) {
        try {
            const response = await  this.$axios.get(`api/me/visitors/form/${siteId}`)
            if (response.data.hasOwnProperty('can_create')) {
                commit('create', response.data.can_create)
                commit('setFormData', {})
            } else {
                commit('create', true)
                commit('setFormData', response.data)
            }
        } catch (err) {
            commit('setFormData', {})
        }
    },

    async updateForm({commit}, payload) {
        try {
            const response = await this.$axios.put(`api/me/visitors/form/${payload.siteId}`, payload.formData)
            if (response.data.succes) {
                commit('setSucces', true);
                this.$router.go();
            }

        } catch (err) {
            commit('setError', err);
        }
    },
    async invite({commit}, payload) {
        try {
            const response = await this.$axios.post(`api/me/visitors/invite`, payload);
            this.$router.go();
        } catch (err) {
            commit('setError', err);
        }

    },

    async fetchVisitors({commit}, id) {
        try {
            const response = await this.$axios.get(`api/me/visitors/${id}`);
            commit('setVisitors', response.data);
        } catch (err) {
            commit('setError', err);
        }
    },

    async fetchInvite({commit}, hash) {
        try {
            const response = await this.$axios.get(`api/me/visitors/invite/${hash}`);
            commit('setInviteData', response.data)
        } catch(err) {
            commit('setError', err);
        }
    },

    async createVisitor({commit}, payload) {
        try {
            const response = await this.$axios.post(`api/me/visitors/invite/${payload.hash}`, payload);
            this.$router.push(`/visitors/qr/${response.data}`)
        } catch (err) {
            commit('setError', err.response.data);
        }
    },

    async fetchVisitor({commit}, id) {
        try {
            const response = await this.$axios.get(`api/me/visitors/profile/${id}`);
            commit('setVisitor', response.data);
        } catch (err) {
            commit('setError', err);
        }
    },

    async fetchVisitorQr({commit}, id) {
        try {
            const response = await this.$axios.get(`api/me/visitors/qr/${id}`);
            commit('setVisitor', response.data);
        } catch (err) {
            commit('setError', err);
        }
    },

    async generateQr({commit}, visitorId) {
        try {
            const response = await this.$axios.get(`api/qr-as-pdf/${visitorId}`, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                responseType: 'blob',
            });
            commit('setPdfQr', response.data);
        } catch (err) {
            commit('setError', err);
        }
    },

    async generateSitePdf({commit}, payload) {
        try {
            const response = await this.$axios.get(`api/generate-qr`, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                responseType: 'blob',
                params: {
                    ...payload
                }

            });
            commit('setPdfQr', response.data);
        } catch (err) {
            commit('setError', err);
        }
    },

}
