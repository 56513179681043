import jwt          from 'jsonwebtoken';
import {EventBus}   from '~/plugins/event-bus';
import LocalStorage from "@/helpers/local-storage";
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({dispatch, state}) {
    if (!state.user?.slug) {
      dispatch('fetch');
    }
  },

  async login({commit}, payload) {
    enableLoader()
    commit('setError', '');

    const {redirectLink = '/', ...credentials} = payload

    try {
      const auth = await this.$axios.$post('/api/auth/login', {
        ...credentials,
      });
      localStorage.clear();
      const decoded = jwt.decode(auth.tokenInfo.accessToken);
      commit('mapUser', decoded.usr);
      commit('setToken', auth.tokenInfo.accessToken);

      redirectLink && this.$router.push(redirectLink);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async requestResetPassword({commit}, email) {
    enableLoader()
    commit('setError', '');
    try {
      const reset = await this.$axios.$post('/api/auth/forgot-password-email', {
        email,
      });
      commit('setInfo', reset);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async resetPassword({commit}, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const reset = await this.$axios.$post(
        '/api/auth/forgot-password-reset',
        payload
      );
      commit('setInfo', reset);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async getUserByToken({commit, state}, decoded) {
    if (decoded) {
      commit('mapUser', decoded.usr);
    }
  },

  async refreshToken({commit, state}) {
    const token = LocalStorage.getToken();//state.token;
    if (!token) {
      return;
    }
    commit('setError', '');
    try {
      this.$axios.setToken(token, 'Bearer');
      const auth = await this.$axios.$post('/api/auth/refresh');
      const decoded = jwt.decode(auth.tokenInfo.accessToken);

      commit('mapUser', decoded.usr);
      commit('setToken', auth.tokenInfo.accessToken);
    } catch (err) {
      commit('setError', err);
    }
  },

  async logout({ commit }) {
    enableLoader()
    commit('setError', '');
    try {
      const auth = await this.$axios.$post('/api/auth/logout');
    } catch (err) {
      commit('setError', err);
    }
    commit('setUser', null);
    commit('setToken', null);

    if (localStorage.getItem('siteControlCurrentTab')) {
      localStorage.removeItem('siteControlCurrentTab');
    }
    disableLoader()

    LocalStorage.removeToken()
    //force page reload to clear application state
    window.location.href = window.location.origin + '/login';
  },

  async fetch({commit}) {
    try {
      const user = await this.$axios.$get('/api/me');
      commit('setUser', user.data);
    } catch (err) {
      //TODO: handle error
      throw err;
    } finally {
    }
  },

  async workerRegister({commit, dispatch}, payload) {
    enableLoader()
    commit('setError', '');
    try {
      await this.$axios.put(
        '/api/me/register',
        payload.formData
      );
      disableLoader()
      this.$router.push(payload.redirectUrl);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async authEmail({commit, dispatch}, route) {
    enableLoader()
    try {
      const verify = await this.$axios.post(route);
      commit('setVerify', verify.data);
      disableLoader()
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  // async resendEmail({commit, dispatch}, email) {
  //     enableLoader()
  //     try {
  //         let resend;
  //
  //         if (email) {
  //             resend = await this.$axios.post('/api/auth/email/resend', {email: email});
  //         } else {
  //             resend = await this.$axios.put('/api/auth/email/resend');
  //         }
  //
  //         const msg = resend.data.message || `Verify email has been resend`;
  //
  //         EventBus.$emit('createSnackbar', {
  //             message: msg
  //         });
  //
  //         // show info for login form
  //         commit('setError', {message: msg});
  //
  //         disableLoader()
  //     } catch (err) {
  //         EventBus.$emit('createSnackbar', {
  //             message: `${err.response.data.message}`
  //         });
  //         commit('setError', err);
  //     }
  //     disableLoader()
  // }
};
