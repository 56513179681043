<template>
    <div class="no-gutters">
        <Sidebar/>

        <div
            class="page-wrapper"
            :class="{'worker-guard-wrapper': noPadding}"
        >
            <TopBar/>

            <div class="nuxt-wrapper container-fluid">
                <nuxt/>
            </div>
        </div>
        <LoaderComponent/>

        <AppDrawer/>

        <UiSnackbarContainer ref="snackbarContainer"/>
    </div>
</template>

<script>
import Echo from 'laravel-echo';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import LoaderComponent from '../components/LoaderComponent.vue';
import AppDrawer from '../components/AppDrawer.vue';
import UiSnackbarContainer from '../components/ui/UiSnackbarContainer';
import checkWidth from '../mixins/checkWidth';
import {EventBus} from '~/plugins/event-bus';
import {isDebug} from "@/helpers/environment";
import LocalStorage from "@/helpers/local-storage";
// import ReactNativeChannelMixin, {ReactNativeMessages} from "@/mixins/reactNativeChannel";

window.io = require('socket.io-client');

export default {
    name: 'defaultLayout',
    components: {
        UiSnackbarContainer,
        Sidebar,
        TopBar,
        LoaderComponent,
        AppDrawer,
    },
    mixins: [checkWidth],
    data() {
        return {
            isSiteControl: false
        }
    },
    created() {
        this.$store.dispatch('dict/init')
        this.snackbarInit()
        this.echoInit()
        this.notificationsInit()

        // this.initWebViewEvents()
    },
    computed: {
        hasDict() {
            return this.$store.getters['dict/hasData'];
        },

        noPadding () {
          return !!window.ReactNativeWebView && (this.isSecurityGuard || this.isWorker)
        },

        user() {
            return this.$store.getters['auth/getUser'];
        },

        isSecurityGuard() {
            return this.$store.getters['auth/isSecurityGuard'];
        },

        isWorker() {
            return this.$store.getters['auth/isWorker'];
        },
    },
    methods: {
        echoInit() {
            const accessToken =
                this.$store.getters['auth/getToken'] ||
                LocalStorage.getToken()

            // No echo when none defined
            //
            if (!process.env.ECHO_URL) {
                return;
            }

            window.Echo = new Echo({
                broadcaster: 'socket.io',
                host: `${process.env.ECHO_URL}`,
                auth: {
                    headers: {Authorization: `Bearer ${accessToken}`},
                },
            });

            if (
                isDebug() ||
                localStorage.getItem('log') === 'true'
            ) {
                console.log('%c ECHO Init', 'color: gold; font-weight: 600');
            }
        },

        // initWebViewEvents() {
        //   this.webViewListen(ReactNativeMessages.RAM_PDF_CLOSE, () => {
        //     EventBus.$emit('rams-preview-closed')
        //   })
        // },

        notificationsInit() {
            const e = window.Echo;

            // No notificatoins if no Echo
            //
            if (!e) {
                return;
            }

            if (this.user) {
                e.private('users.' + this.user.id)
                    .notification((notification) => {
                        this.$store.dispatch('notifications/add', notification)
                    });
            } else {
                console.warn('Unable to setup notifications.')
            }

        },

        handleResendVerify() {
            this.$store.dispatch('auth/resendEmail');
        },

        snackbarInit() {
            EventBus.$on('createSnackbar', msg => this.createSnackbar(msg));
        },

        createSnackbar(message) {
            const defaultConfig = {
                transition: 'slide',
                queueSnackbars: false,
                action: '',
                duration: 3 * 1000,
                actionColor: 'accent',
            };

            if (this.$refs.snackbarContainer) {
                this.$refs.snackbarContainer.createSnackbar(
                    Object.assign(defaultConfig, message)
                );
            }
        },
    },
};
</script>

<style lang="scss">
.click {
    cursor: pointer;
}
</style>
