export default {
  setWorkers: (state, workers) => {
    state.workers = workers;
  },

  addWorkers: (state, workers) => {
    state.workers = state.workers.concat(workers);
  },

  setWorkerOnsiteStatus: (state, status) => {
    const worker = state.workers.find( worker => worker.id === status.user_id)
    if (worker) {
      Object.assign(worker, {
        onsite: status.onsite ? status.site_id : null,
        onsite_checkin: status.onsite ? status.check_time : null
      })
    }
  },

  toggleData: state => {
    state.hasData = true;
  },

  setLinks: (state, links) => {
    state.links = links;
  },

  setMeta: (state, meta) => {
    state.meta = meta;
  },

  setError: (state, err) => {
    state.error = err;
  },

  setSimpleWorker: (state, worker) => {
    state.simpleWorker = worker;
  },

  setAssignments: (state, assignments) => {
    state.assignments = assignments;
  },

  setTimesheets: (state, timesheets) => {
    state.timesheets = timesheets;
  },

  setContracts: (state, contracts) => {
    state.contracts = contracts;
  },

  setBatchInfo: (state, info) => {
    if (null === info) {
      state.batchInfo = null;
      return;
    }

    state.batchInfo = info.data;
  },
  setClear: (state, data) => {
    state.clear = data;
  },
  setCompanies: (state, data) => {
    state.companies = data;
  },
  setSite: (state, data) => {
    state.site = data;
  },
  setFilters: (state, filters) => {
    Object.keys(filters).forEach(key => {
      let resetItem = { id: null, name: 'All' };
      if (key === 'statuses') {
        resetItem = 'All';
      }

      if (filters[key].hasOwnProperty('length')) {
        filters[key] = [resetItem, ...filters[key]];
      }
    });

    state.filters = filters;
  },
  setFilter: (state, filter) => {
    if (Object.keys(filter).length) {
      state.filter[filter.key] = filter.value;
    } else {
      state.filter = {};
    }
  },
  setNewWorker: (state, data) => {
    state.newWorker = data;
  },

  setWorkerCheckins: (state, {checkins, replace}) => {
    state.workerCheckins = 
      replace 
        ? checkins 
        : [].concat(state.workerCheckins).concat(checkins)
  },
  
  setWorkerCheckinsMeta: (state, checkinsMeta) => {
    state.workerCheckinsMeta = checkinsMeta
  },

  setCheckInExcelUrl: (state, url) => {
    state.checkInExcelUrl = url;
  },

};
