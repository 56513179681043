<template>
  <div class="navigation-link--container">
    <nuxt-link
      class="row align-items-center flex-nowrap"
      :class="classes"
      :to="routeName"
    >
      <div v-if="iconPresent" class="navigation-link__icon" :class="{hasNew: isAlert}">
        <slot></slot>
      </div>
      <span>{{ title }}</span>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'NavbarLink',
  props: {
    iconPresent: Boolean,
    isAlert: {
      type: Boolean,
      default: false
    },
    className: String,
    title: String,
    routeName: {
      type: String,
      default: '/',
    },
  },
  computed: {
    classes() {
      const DEFAULT_CLASS_NAME = 'navigation-link__item';

      return this.className
        ? `${this.className} ${DEFAULT_CLASS_NAME}`
        : DEFAULT_CLASS_NAME;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/global_vars';

.navigation-link--container {
  transition: 0.1s ease-out;

  .navigation-link {
    &__item {
      font-size: 16px;
      font-weight: 500;
      color: $dark-main;
      text-transform: capitalize;
      margin: 0 auto;
      padding: 15px;
      transition: 0.1s;

      &.uppercase {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;

        span {
          font-size: 14px;
        }
      }

      &:visited {
        color: $dark-main;
      }

      .navigation-link__icon {
        color: $dark-main;
        width: 24px;
        margin: auto;

        &.hasNew {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            $size: 10px;
            display: block;
            height: $size;
            width: $size;
            border-radius: 50%;
            background-color: $alert;
            top: 1px;
            left: 80%;

            @media (max-width: $mobileWidth) {
              right: 19%;
            }
          }
        }

      }

      span {
        max-width: 0;
        max-height: 0;
        font-size: 0;
        opacity: 0;
      }
    }
  }
  .nuxt-link-exact-active, .nuxt-link-active {
    background-color: rgba(255, 223, 36, 0.08);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 6px;
      background-color: $brand;
      left: 0;
    }

    .navigation-link__icon {
      color: $brand;
    }
  }

  &:hover {
    background-color: #fafafa;
    color: #424242;
  }
}
</style>
