export default {
  getSites: state => {
    return state.sites
  },
  getActiveSites: state => {
    return state.sites.filter(val => val.active)
  },
  getSite: state => {
    return state.site
  },
  getSiteStats: state => {
    return state.siteStats
  },
  getSiteWorkers: (state) => (dataSlotName) => {
    return state.workers[dataSlotName ?? 'default']
  },
  getSiteWorkersMeta: state => (dataSlotName) => {
    return state.workersMeta[dataSlotName ?? 'default']
  },
  getError: state => {
    return state.error
  },
  getFirePrintUrl: state => {
    return state.firePrintUrl
  },
  getSubcontractors: state => {
    return state.subcontractors
  },
  getGates: state => {
    return state.gates
  },
  getSubcontractor: state => {
    return state.subcontractor
  },
  getMeta: state =>{
    return state.meta;
  },
  getCheckInExcelUrl: state => {
    return state.checkInExcelUrl
  },
  getCheckins: state => {
    return state.checkins
  },
  getCheckinsMeta: state => {
    return state.checkinsMeta
  },
  getSubcompanyRequests: state => {
    return state.subcompanyRequests
  },
  getWorkerDocAuditRecs: state => {
    return state.workerDocAuditRecs
  },
  getWorkerDocAuditRecsMeta: state => {
    return state.workerDocAuditRecsMeta
  },
  getSiteCheckins: state => {
    return state.siteCheckins
  },
  getSiteCheckinsMeta: state => {
    return state.siteCheckinsMeta
  },
};
